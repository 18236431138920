import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }: PropsWithChildren<{}>) => {
  if (typeof window === 'undefined') return null;
  // Create the root element that content is rendered into
  // Used for "Portal" or other functionality that querySelectors for the '#root'
  let portalRoot = document.getElementById('root');
  if (!portalRoot) {
    portalRoot = document.createElement('div');
    portalRoot.setAttribute('id', 'root');
    portalRoot.setAttribute('data-testid', 'portal');
    portalRoot = document.body.appendChild(portalRoot);
  }

  return createPortal(children, portalRoot);
};

export default Portal;

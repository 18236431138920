import Composition from 'src/lib/HOC/Composition';
import { formatModalId } from 'src/lib/Utils/helpers';
import { data } from './data';
import Modal from './index';

const { component, compositionFunction } = Composition(Modal)<typeof data>(
  ({ rendering, ...rest }) => ({
    id: formatModalId(rendering?.dataSource),
    rendering: { ...rendering, ...rest },
  })
);

export { compositionFunction };
export default component;
